import React from "react"
import PropTypes from "prop-types"

const InputComponent = ({
  type = "text",
  css,
  name,
  id,
  change,
  value,
  placeholder,
}) => {
  return (
    <input
      type={type}
      className={css ? `${css} "validate-required"` : "validate-required"}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={change}
      name={name}
    />
  )
}

InputComponent.propTypes = {
  type: PropTypes.string,
  css: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  change: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputComponent
