import React from "react"
import PropTypes from "prop-types"

const SubmitButton = ({ validation, loading }) => {
  return (
    <button disabled={!validation} type="submit">
      Send Message
      {loading && <div className="form-loading" />}
    </button>
  )
}
SubmitButton.propTypes = {
  validation: PropTypes.bool,
  loading: PropTypes.bool,
}
export default SubmitButton
