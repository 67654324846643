import { useState, useCallback } from "react"

export default function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      })
    },
    useCallback(function (field, data) {
      const newFields = {
        ...fields,
        [field]: data,
      }
      setValues(newFields)
    }),
  ]
}
