import React from "react"
import useFormFields from "../../../../libs/useFormFields"
import InputComponent from "./InputComponent"
import SubmitButton from "./SubmitButton"

const Form = () => {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    name: "",
    contact: "",
    subject: "",
    message: "",
  })

  function validateForm() {
    return (
      fields.email.length > 5 &&
      fields.name.length > 2 &&
      fields.contact.length < 1
    )
  }
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  async function submitForm(e) {
    setLoading(true)
    e.preventDefault()
    console.log(" dont Know", fields)
    try {
      let data = await submitData(fields, "/.netlify/functions/contact")
      data = data.json()

      setSuccess("The Ajax was a mistake. We are fixing it")
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "CONTACT", ...fields }),
      })
      setLoading(false)
    } catch (e) {
      console.error("error", e)
      setError("An error occured please try again")
      setLoading(false)
    }
  }

  function submitData(data, api) {
    return fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          {/* <ContactInfo {...defaultInfo} /> */}
          <div className="col-sm-6 col-md-5 col-md-offset-1">
            <form onSubmit={submitForm} data-netlify="true" name="contact">
              <InputComponent
                id={"name"}
                placeholder={"Your Name"}
                value={fields.name}
                change={handleFieldChange}
                name="name"
              />
              <InputComponent
                id={"email"}
                css={"validate-email"}
                placeholder={"Email Address"}
                change={handleFieldChange}
                value={fields.email}
                name="email"
              />
              <InputComponent
                id={"subject"}
                placeholder={"Message Subject"}
                value={fields.subject}
                change={handleFieldChange}
                name="subject"
              />
              <textarea
                className="validate-required"
                name="message"
                rows="4"
                placeholder="Message"
                id="message"
                value={fields.message}
                onChange={handleFieldChange}
              ></textarea>
              <input
                type="text"
                name="formName"
                className="col-md-6 hidden"
                placeholder="form Name"
                value={fields.contact}
                id="contact"
                onChange={handleFieldChange}
              />
              <SubmitButton validation={validateForm()} loading={loading} />
              {success && (
                <div className="form-success">
                  Thanks for your interest, we will send you an email shortly.
                  Don't forget to check your spam.
                </div>
              )}
              {error && <div className="form-error">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Form
